import React, { useRef } from "react";
import "./site.css";

const HomePage = () => {
  const sectionRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="header">
        <a className="logo" href="#">
          YOGESH KUMAR
        </a>
        <div className="navBar">
          <button>HOME</button>
          <button onClick={scrollToSection}>EXPERIENCE</button>
          <button>POSTS</button>
          <button onClick={scrollToContact}>CONTACT</button>
        </div>
      </div>
      <div className="content-head">
        <div className="contenhdone">
          <h1>Let's Create Something Amazing Together!</h1>
          <p>
            Hey there! I'm Yogesh Kumar, your go-to eCommerce wizard and code
            ninja. If you’re looking for someone who can speak fluent
            JavaScript, Python, and HTML while designing logos that make you say
            "Wow!", then you're in the right place. I design, develop, and
            deliver everything from dazzling websites to killer apps, all with a
            sprinkle of humor and a dash of creativity. Dive into my portfolio
            to witness the magic, or shoot me a message so we can start cooking
            up something amazing together!
          </p>
        </div>
        <div className="contenhdtwo"></div>
      </div>
      <div className="hireCont">
        <button id="hi3re" onClick={scrollToContact}>
          HIRE ME...
        </button>
      </div>
      <div className="bottom-content" ref={sectionRef}>
        <div className="lang-exp">
          <h3>Language experience</h3>
          <div className="lang-row">
            <div className="lang-box">
              <img src="/js.png" alt="" />
              <span>Javascript</span>
            </div>
            <div className="lang-box">
              <img src="/dart.webp" alt="" />
              <span>Dart</span>
            </div>
            <div className="lang-box">
              <img src="/python.png" alt="" />
              <span>Python</span>
            </div>
          </div>
        </div>
        <div className="lang-exp">
          <h3>Backend serviceces</h3>
          <div className="lang-row">
            <div className="lang-box">
              <img src="/web.png" alt="" style={{ backgroundColor: "white" }} />
              <span>AWS</span>
            </div>
            <div className="lang-box">
              <img src="/firebase-logo.png" alt="" />
              <span>Firebase</span>
            </div>
            <div className="lang-box">
              <img src="/mongodb.svg" alt="" />
              <span>Mongo DB</span>
            </div>
          </div>
        </div>
        <div className="recent-posts">
          <div className="recent-head">
            <span>Recent Posts</span>
            <button className="post-button">View all</button>
          </div>
          <div className="post-tile">
            <img src="/firebase-logo.png" alt="" />
            <div className="post-content">
              <span>
                <b>Published on:</b>6 June, 2024
              </span>
              <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
              <p>
                Build Your Next Project on MongoDB Atlas, the Cloud-Native
                Document Database as a Service. The Easiest Way to Deploy,
                Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                Quick Setup. Continuous Backups.
              </p>
            </div>
          </div>
          <div className="post-tile">
            <img src="/firebase-logo.png" alt="" />
            <div className="post-content">
              <span>
                <b>Published on:</b>6 June, 2024
              </span>
              <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
              <p>
                Build Your Next Project on MongoDB Atlas, the Cloud-Native
                Document Database as a Service. The Easiest Way to Deploy,
                Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                Quick Setup. Continuous Backups.
              </p>
            </div>
          </div>
          <div className="post-tile">
            <img src="/firebase-logo.png" alt="" />
            <div className="post-content">
              <span>
                <b>Published on:</b>6 June, 2024
              </span>
              <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
              <p>
                Build Your Next Project on MongoDB Atlas, the Cloud-Native
                Document Database as a Service. The Easiest Way to Deploy,
                Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                Quick Setup. Continuous Backups.
              </p>
            </div>
          </div>
          <div className="post-tile">
            <img src="/firebase-logo.png" alt="" />
            <div className="post-content">
              <span>
                <b>Published on:</b>6 June, 2024
              </span>
              <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
              <p>
                Build Your Next Project on MongoDB Atlas, the Cloud-Native
                Document Database as a Service. The Easiest Way to Deploy,
                Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                Quick Setup. Continuous Backups.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-head" ref={contactRef}>
        <h5>Connect with me</h5>
        <div className="contact-phone">
          📞 +91 85805 73129/📧 yogeshkumar55119@gmail.com
        </div>
      </div>
      <div className="contact-form">
        <label htmlFor="name-input">Name*</label>
        <input type="text" id="name-input" />
        <label htmlFor="phone-input">Phone</label>
        <input type="number" id="phone-input" />
        <label htmlFor="email-input">Email*</label>
        <input type="email" id="email-input" />
        <label htmlFor="text-mess">Message</label>
        <textarea name="message" id="text-mess" rows={10}></textarea>
        <button id="contact-submit">Submit</button>
      </div>
      <footer>Copyright ©️ 2024 yogesh-kumar.com</footer>
    </>
  );
};

export default HomePage;
