import React, { useState, useRef, useEffect } from "react";
import "./site.css";
import { setTitle } from "../widgets/setTitle";
import { db, storage } from "../../firebase";
import Toast from "../widgets/toast";
import {
  getFirestore,
  getDoc,
  doc,
  query,
  updateDoc,
  orderBy,
  startAfter,
  limit,
  collection,
  Timestamp,
  getDocs,
  serverTimestamp,
  where,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const Posts = () => {
  setTitle("Posts - Yogesh Kumar");
  const postsCollection = collection(db, "posts");
  const [isWriting, setIswriting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocument, setLastDocument] = useState(null);
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [imgFileOne, setImgFileOne] = useState("");
  const [imgFileTwo, setImgFileTwo] = useState("");
  const [imgFileThree, setImgFileThree] = useState("");

  const [postTitle, setPostTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [descOne, setDescOne] = useState("");
  const [descTwo, setDescTwo] = useState("");
  const [toastText, setToastText] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    // getPosts();

    const handleOnlineStatus = () => {
      if (navigator.onLine) {
        window.location.reload();
      }
    };

    window.addEventListener("online", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
    };
  }, []);

  async function uploadImages() {
    uploadSingleImage(imgFileOne);
    uploadSingleImage(imgFileTwo);
    uploadSingleImage(imgFileThree);
  }

  async function uploadSingleImage(file) {
    if (file) {
      const storageRef = ref(
        storage,
        `blog_images/blog${file.name}.yogesh-kumar.com`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      const snapshot = await uploadTask;
      const downloadURL = await getDownloadURL(snapshot.ref);
      setPostImageUrls((prevUrls) => [...prevUrls, downloadURL]);
    }
  }

  // const getPosts = async () => {
  //   let quer = postsCollection;
  //   setIsLoading(true);
  //   if (lastDocument) {
  //     quer = query(
  //       postsCollection,
  //       orderBy("publish_time", "desc"),
  //       limit(1),
  //       startAfter(lastDocument)
  //     );
  //   } else {
  //     quer = query(postsCollection, orderBy("publish_time", "desc"), limit(20));
  //   }

  //   await getDocs(quer)
  //     .then((snapshot) => {
  //       if (snapshot.docs.length > 0) {
  //         lastDocument = snapshot.docs[snapshot.docs.length - 1];

  //         snapshot.forEach((docr) => {
  //           // createPost(docr.data(), docr.id);
  //           console.log(docr);
  //         });

  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error getting Data.");
  //     });
  // };

  const createPost = async () => {
    if (author.trim() === "") {
      setToastText("Please enter author name first");
      setShowToast(true);
      return;
    }
    if (authorEmail.trim() === "") {
      setToastText("Please enter author email first");
      setShowToast(true);
      return;
    }
    if (postTitle.trim() === "") {
      setToastText("Please enter post title first");
      setShowToast(true);
      return;
    }
    if (descOne.trim() === "") {
      setToastText("Please enter post descreption first");
      setShowToast(true);
      return;
    }
    if (imagePreview === null) {
      setToastText("Please select post front image first");
      setShowToast(true);
    }
    setIsLoading(true);
    await uploadImages();
    await addDoc(postsCollection, {
      title: postTitle.trim(),
      publish_time: Timestamp.now(),
      b_imgs: postImageUrls,
      author: author.trim(),
      author_email: authorEmail.trim(),
      desc_one: descOne.trim(),
      desc_two: descTwo.trim(),
      is_approved: true,
    })
      .then(() => {
        setIsLoading(false);
        window.location.reload();
      })
      .catch((e) => console.error(e));
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleDiv2Click = () => {
    fileInputRef2.current.click();
  };

  const handleDiv3Click = () => {
    fileInputRef3.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFileOne(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFile2Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFileTwo(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview2(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFile3Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFileThree(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview3(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    fileInputRef.current.value = null;
  };

  const handleRemoveImage2 = () => {
    setImagePreview2(null);
    fileInputRef2.current.value = null;
  };

  const handleRemoveImage3 = () => {
    setImagePreview3(null);
    fileInputRef3.current.value = null;
  };

  const writeFunc = () => {
    setIswriting(!isWriting);
  };

  return (
    <>
      <div className="my-posts">
        <div className="recent-head">
          <span>{isWriting ? "Write a post" : "Recent posts"}</span>
          <button className="post-button" onClick={writeFunc}>
            {isWriting ? "Close" : "Write"}
          </button>
        </div>
        {!isWriting && (
          <div className="post-box">
            <div className="post-tile">
              <img src="/firebase-logo.png" alt="" />
              <div className="post-content">
                <span>
                  <b>Published on:</b>6 June, 2024
                </span>
                <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
                <p>
                  Build Your Next Project on MongoDB Atlas, the Cloud-Native
                  Document Database as a Service. The Easiest Way to Deploy,
                  Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                  Quick Setup. Continuous Backups.
                </p>
              </div>
            </div>
            <div className="post-tile">
              <img src="/firebase-logo.png" alt="" />
              <div className="post-content">
                <span>
                  <b>Published on:</b>6 June, 2024
                </span>
                <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
                <p>
                  Build Your Next Project on MongoDB Atlas, the Cloud-Native
                  Document Database as a Service. The Easiest Way to Deploy,
                  Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                  Quick Setup. Continuous Backups.
                </p>
              </div>
            </div>
            <div className="post-tile">
              <img src="/firebase-logo.png" alt="" />
              <div className="post-content">
                <span>
                  <b>Published on:</b>6 June, 2024
                </span>
                <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
                <p>
                  Build Your Next Project on MongoDB Atlas, the Cloud-Native
                  Document Database as a Service. The Easiest Way to Deploy,
                  Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                  Quick Setup. Continuous Backups.
                </p>
              </div>
            </div>
            <div className="post-tile">
              <img src="/firebase-logo.png" alt="" />
              <div className="post-content">
                <span>
                  <b>Published on:</b>6 June, 2024
                </span>
                <h4>Lorem jhlkjH kjkhsad kajsdlkfh hjk.</h4>
                <p>
                  Build Your Next Project on MongoDB Atlas, the Cloud-Native
                  Document Database as a Service. The Easiest Way to Deploy,
                  Operate, and Scale MongoDB in the Cloud in Just a Few Clicks.
                  Quick Setup. Continuous Backups.
                </p>
              </div>
            </div>
            <button className="load-more">Load more...</button>
          </div>
        )}
        {isWriting && (
          <div className="writing-box">
            <div className="img-list-box">
              <div className="img-box-head-row">
                <span>Thumbnail</span>
                {imagePreview && (
                  <button className="rem-img" onClick={handleRemoveImage}>
                    Remove
                  </button>
                )}
              </div>
              <div className="img-box" onClick={handleDivClick}>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Selected"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                  />
                ) : (
                  "+"
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
              <div className="img-box-head-row">
                <span>Descreption Image</span>
                {imagePreview2 && (
                  <button className="rem-img" onClick={handleRemoveImage2}>
                    Remove
                  </button>
                )}
              </div>
              <div className="img-box-hor" onClick={handleDiv2Click}>
                {imagePreview2 ? (
                  <img
                    src={imagePreview2}
                    alt="Selected"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "12px",
                    }}
                  />
                ) : (
                  "+"
                )}
                <input
                  type="file"
                  ref={fileInputRef2}
                  style={{ display: "none" }}
                  onChange={handleFile2Change}
                  accept="image/*"
                />
              </div>
              <div className="img-box-head-row">
                <span>Bottom Image</span>
                {imagePreview3 && (
                  <button className="rem-img" onClick={handleRemoveImage3}>
                    Remove
                  </button>
                )}
              </div>
              <div className="img-box-hor" onClick={handleDiv3Click}>
                {imagePreview3 ? (
                  <img
                    src={imagePreview3}
                    alt="Selected"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "12px",
                    }}
                  />
                ) : (
                  "+"
                )}
                <input
                  type="file"
                  ref={fileInputRef3}
                  style={{ display: "none" }}
                  onChange={handleFile3Change}
                  accept="image/*"
                />
              </div>{" "}
            </div>
            <div className="input-box">
              <label htmlFor="author-input">Author name</label>
              <input
                type="text"
                value={author}
                id="author-input"
                onChange={(e) => setAuthor(e.target.value)}
              />
              <label htmlFor="writer-email-input">Author email</label>
              <input
                type="email"
                value={authorEmail}
                id="writer-email-input"
                onChange={(e) => setAuthorEmail(e.target.value)}
              />
              <label htmlFor="title-input">Post title</label>
              <input
                type="text"
                id="title-input"
                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
              />
              <label htmlFor="text-desc">Descreption</label>
              <textarea
                name="descreption"
                id="text-desc"
                rows={10}
                value={descOne}
                onChange={(e) => setDescOne(e.target.value)}
              ></textarea>
              <label htmlFor="text-desc2">Descreption 2</label>
              <textarea
                name="descreption2"
                id="text-desc2"
                rows={10}
                value={descTwo}
                onChange={(e) => setDescTwo(e.target.value)}
              ></textarea>
              <button id="post-submit" onClick={createPost}>
                Submit post
              </button>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="lo4ade3r-bg">
          <div className="lo44ade3r"></div>
        </div>
      )}
      <Toast
        message={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </>
  );
};

export default Posts;
