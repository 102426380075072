import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD6cXFb-Et8tyjWHm4dK28N8XefavfGUnk",
  authDomain: "yogesh-kumar-a38f1.firebaseapp.com",
  projectId: "yogesh-kumar-a38f1",
  storageBucket: "yogesh-kumar-a38f1.appspot.com",
  messagingSenderId: "508911951167",
  appId: "1:508911951167:web:ca7e44d517557293ca878a",
  measurementId: "G-M8PBV4M39M",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
