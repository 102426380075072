import React, { useState, useRef } from "react";
import "./site.css";
import { setTitle } from "../widgets/setTitle";

const SinglePost = () => {
  setTitle("Post - Yogesh Kumar");
  return <></>;
};

export default SinglePost;
