import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/views/home";
import Posts from "./components/views/posts";
import SinglePost from "./components/views/single-post";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/single-post" element={<SinglePost />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
